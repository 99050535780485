/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import logoColor from "../images/logo-color.svg"


const HeaderNav = ({ color, seccion }) => {
  
  const [showMobNav, setShowMobNav] = React.useState(false)
  
  return (
    <div id="header-nav" className={"row"}>
		<div className={"col-5 col-sm-3 text-center"}>
			<Link to="/"><img className={"logo"} src={color ? logoColor : logo} alt="Raiconet - Logística mundial" title="Raiconet - Logística mundial" /></Link>
		</div>
		<div className={"col-sm-6 d-none d-sm-flex"}>
			<nav className={"navbar navbar-expand-lg navbar-light static-top"}>
				<div className={"container"}>
					<div className={"navbar-collapse"} id="navbarResponsive">
						<ul className={"navbar-nav ml-auto"}>
							<li className={"nav-item"}>
								<Link className={"nav-link mv empresaLink"} to="/empresa">EMPRESA</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv"} to="/#servicios">SERVICIOS</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv importacionLink"} to="/importacion">IMPORTACIÓN</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv exportacionLink"} to="/exportacion">EXPORTACIÓN</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv maritimoLink"} to="/maritimo">MARÍTIMO</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv nacionalLink"} to="/nacional">NACIONAL</Link>
							</li>
							<li className={"nav-item"}>
								<Link className={"nav-link mv contactoLink"} to="/contacto">CONTACTO</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>
		<div id="language" className={"col-3 offset-4 offset-sm-0 d-none d-sm-block"} style={{position: 'relative', textAlign: 'right', paddingTop: '10px'}}>
			<a id="loginLink"
			style={{ position: 'absolute', right: '15px', top: '-20px', fontSize: '0.8em' }}
			className="nav-link d-none d-sm-block" target="_blank" href="https://herramientas.raiconet.com/login/auth">LOGIN</a>
			<ul className="d-flex justify-content-end">
				{/* <li className="nav-item d-none d-sm-block"><a className="nav-link" target="_blank" href="http://www.raiconet.com/wp-content/uploads/2020/09/aviso_227153.pdf">COVID-19</a></li> */}
				<li className="nav-item d-none d-sm-block"><a className="nav-link" target="_blank" href="https://www.raiconews.com/">RAICO NEWS</a></li>
				<li className="nav-item"><Link className="nav-link" to={"/en/"+(seccion != 'index' ? seccion : '')}>ENGLISH</Link></li>
			</ul>
			
		</div>
		<div id="menuMob" className={"col-3 offset-4 d-sm-none"} style={{position: 'relative', textAlign: 'right', fontSize: '0.8em' }}>			
			<ul className="d-flex justify-content-end">				
				<li className="nav-item"><Link className="nav-link" to={"/en/"+(seccion != 'index' ? seccion : '')}>ENGLISH</Link></li>
				<li className="nav-item">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16"
					onClick={ () => setShowMobNav(!showMobNav) }
					>
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>
				</li>
				{showMobNav && <li id="menuMobNav">
							<Link className={"nav-link mv empresaLink"} to="/empresa">EMPRESA</Link>
							<Link className={"nav-link mv"} to="/#servicios">SERVICIOS</Link>
							<Link className={"nav-link mv importacionLink"} to="/importacion">IMPORTACIÓN</Link>
							<Link className={"nav-link mv exportacionLink"} to="/exportacion">EXPORTACIÓN</Link>
							<Link className={"nav-link mv maritimoLink"} to="/maritimo">MARÍTIMO</Link>
							<Link className={"nav-link mv nacionalLink"} to="/nacional">NACIONAL</Link>
							<Link className={"nav-link mv contactoLink"} to="/contacto">CONTACTO</Link>
				</li> 
				}
			</ul>
			
		</div>
	</div>
  )
}
HeaderNav.defaultProps = {
  color: false,
  seccion: ''
}
export default HeaderNav
