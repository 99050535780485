import * as React from "react"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll'
import logo from "../images/bco.svg"
import dataFiscal from "../images/DATAWEB.jpg"
import terminos from "../pdf/Terminos-y-condiciones-2022.pdf"
import HoraArg from "../components/horaArg"
import FooterMob from "../components/footerMob"
import FooterDesk from "../components/footerDesk"

const Footer = () => {
  

 
	return (
			<>
				<FooterDesk />
				<FooterMob />
			</>
		)
}

export default Footer
